import FF from "../../components/forms/FormField.module.css";

import { Navigate } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { Input } from "react-aria-components";
import { Controller, type SubmitHandler, useForm, useFormContext } from "react-hook-form";
import ContentPane from "../../components/ContentPane.tsx";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { CreateAndAssignIpField } from "../../components/forms/CreateAndAssignIpField.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { MonthlyCostElement, MonthlyCostField } from "../../components/forms/MonthlyCostField.tsx";
import TF from "../../components/forms/TextField.module.css";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WTextField, type WTextFieldProps } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { LoadingProgress } from "../../components/loading/Loading.tsx";
import { useConfig } from "../../config.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useOnce } from "../../utils/react/useOnce.ts";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { type VmPriceFieldsOmitSize, VmSizeSelect } from "../compute/VmSizeSelect.tsx";
import { extractOsFields } from "../compute/os/os.ts";
import { usePackages } from "../compute/vmPackages.ts";
import { parametersByNameAtom } from "../compute/vmParametersQuery.ts";
import { getSizeParams, sizeToVmPriceFields, type SizeValue } from "../compute/vmSizeSelectUtils.ts";
import { LocationFieldCustom } from "../location/LocationField.tsx";
import { useLocationsForType } from "../location/query.ts";
import { VpcNetworkSelect } from "../network/VpcNetworkSelect.tsx";
import { PricingModal } from "../pricing/PricingModal.tsx";
import { pricesAtom } from "../pricing/query.ts";
import {
    getServiceCreatePrice,
    type Price,
    type ResourcePrices,
    type VmPriceFields,
} from "../pricing/resourcePricing.ts";
import { FailoverNodeSelect } from "./FailoverNodeSelect.tsx";
import { serviceLink } from "./links.ts";
import { OsVersionSelectCustom } from "./os/OsVersionSelect.tsx";
import { type ServiceCreateInputs, ServiceCreateViewModel } from "./serviceCreateViewModel.ts";
import * as serviceImagesQuery from "./serviceImagesQuery.ts";
import { createServiceMutation, SERVICE_CREATE_TIMER } from "./servicesQuery.ts";

interface ServiceCreateProps {
    inputs?: Partial<ServiceCreateInputs>;
    previousRequestUuid?: string;
    onChangeInputs?: (inputs: ServiceCreateInputs) => void;
}

export function ServiceCreate(props: ServiceCreateProps) {
    return import.meta.env.DEV ? <ServiceCreateDebugger {...props} /> : <ServiceCreateForm {...props} />;
}

export function ServiceCreateForm({ inputs, previousRequestUuid, onChangeInputs }: ServiceCreateProps) {
    //#region Hooks

    const locations = useLocationsForType("managed_service");
    const allImages = serviceImagesQuery.useSuspense();
    const allSizePackages = usePackages();

    const vmParams = useSuspenseQueryAtom(parametersByNameAtom);

    const { kubernetesEnabled, privateNetworksEnabled } = useConfig();
    const availableImages = kubernetesEnabled ? allImages : allImages.filter((i) => i.service_name !== "kubernetes");

    const mutation = useStandardMutation(createServiceMutation);

    const requestUuid = useGenerateUuid(previousRequestUuid);

    const viewModel = useOnce(() => {
        const sizeParams = getSizeParams(vmParams);
        return new ServiceCreateViewModel(availableImages, locations, allSizePackages, sizeParams);
    });

    /// Form
    const form = useForm<ServiceCreateInputs>({
        disabled: mutation.isPending,

        mode: "onBlur",
        reValidateMode: "onBlur",

        defaultValues: {
            ...(viewModel?.getDefaultValues(inputs) ?? {}),
            ...inputs,
        },
    });

    const { watch, control, setValue, getValues } = form;

    viewModel.attachGetterAndSetter(getValues, setValue);

    useEffect(() => {
        const subscription = watch(() => {
            onChangeInputs?.(getValues()); // for form debugger
        });
        return () => subscription.unsubscribe();
    }, [watch, getValues, onChangeInputs]);

    //#endregion Hooks

    if (mutation.isSuccess) {
        return <Navigate {...serviceLink(mutation.data)} />;
    }

    function getSelectedImage() {
        const serviceValue = watch("service");
        return allImages.find((i) => i.service_name === serviceValue.os_name)!;
    }

    function getMultiNodeSettings(): { available: boolean; description?: string } {
        const serviceImage = getSelectedImage();
        return {
            available: serviceImage.is_multi_node_available,
            description: serviceImage.multi_node_description,
        };
    }

    function isKubernetesSelected() {
        return getSelectedImage().service_name === "kubernetes";
    }

    function priceCalculator(prices: ResourcePrices, service: VmPriceFields): Price {
        const {
            // Discard these fields
            current_pool_uuid: _1,
            designated_pool_uuid: _2,
            ...serviceFields
        } = service;
        return getServiceCreatePrice(
            prices,
            { ...serviceFields, status: "running", price_multipliers: getSelectedImage().prices },
            false,
            /** Package price is always calculated for a single node */
            0,
        );
    }

    const onSubmit: SubmitHandler<ServiceCreateInputs> = async (inputs) => {
        // extract some props from body
        const { location, size, service, network_uuid, failover_nodes, ...body } = inputs;
        const final_network_uuid: string = network_uuid ?? "";
        await mutation.mutateAsync({
            requestUuid,
            inputs,
            body: {
                ...body,
                is_multi_node: failover_nodes > 0,
                service: service.os_name,
                version: service.os_version,
                vm_cpu: size.vcpu,
                vm_disk_gb: size.disks,
                vm_ram: size.ram,
                package_parameters: {
                    location,
                    network_uuid: final_network_uuid,
                },
            },
        });
    };

    const multiNodeSettings = getMultiNodeSettings();
    const os = extractOsFields(watch("service"));
    const { os_name } = os;
    const createFields = {
        ...os,
        location: watch("location"),
    } satisfies VmPriceFieldsOmitSize;

    return (
        <WHookForm
            form={form}
            onSubmit={onSubmit}
            loader={
                mutation.isPending && (
                    <LoadingProgress
                        name={form.getValues().display_name}
                        type="managed_service"
                        lifeTime={SERVICE_CREATE_TIMER}
                    />
                )
            }
        >
            <CreateHero />

            <FormField wide label="Service" isRequired>
                <div className={FF.FormFieldRadioGroup}>
                    <Controller
                        control={control}
                        name="service"
                        render={({ field }) => (
                            <OsVersionSelectCustom
                                items={availableImages}
                                value={field.value}
                                onChange={viewModel.onChangeService}
                            />
                        )}
                    />
                </div>
            </FormField>

            {locations.showLocations && (
                <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                        <LocationFieldCustom
                            value={field.value}
                            onChange={viewModel.onLocationChange}
                            locations={locations}
                        />
                    )}
                />
            )}

            {!isKubernetesSelected() && (
                <FormField wide label="Size" isRequired>
                    <div className={FF.FormFieldRadioGroup}>
                        <Controller
                            control={control}
                            name="size"
                            render={() => (
                                <VmSizeSelect
                                    priceFields={createFields}
                                    value={watch("size")}
                                    ranges={watch("size_ranges")}
                                    onChange={viewModel.onSizeChange}
                                    priceCalculator={priceCalculator}
                                />
                            )}
                        />
                    </div>
                </FormField>
            )}

            <Separator />

            {multiNodeSettings.available && (
                <FormField isRequired label="Fail-over nodes" description={multiNodeSettings.description}>
                    <Controller
                        control={control}
                        name="failover_nodes"
                        render={({ field }) => (
                            <FailoverNodeSelect
                                size={watch("size")}
                                location={watch("location")}
                                serviceName={os_name}
                                isRequired
                                isDisabled={field.disabled}
                                valueKey={String(field.value) ?? null}
                                onChange={(_, key) => field.onChange(Number(key))}
                            />
                        )}
                    />
                </FormField>
            )}

            {!isKubernetesSelected() && (
                <CreateAndAssignIpField<ServiceCreateInputs>
                    name="reserve_public_ip"
                    control={control}
                    location={watch("location")}
                />
            )}

            {privateNetworksEnabled && !isKubernetesSelected() && (
                <FormField
                    isRequired
                    label="VPC network"
                    description="Choose a VPC network for your resource from already existing one or create a new private network."
                >
                    <Controller
                        control={control}
                        name="network_uuid"
                        render={({ field }) => (
                            <VpcNetworkSelect
                                location={watch("location")}
                                isRequired
                                isDisabled={field.disabled}
                                valueKey={field.value ?? null}
                                onChange={(_vpc, key) => field.onChange(key)}
                            />
                        )}
                    />
                </FormField>
            )}

            <Separator />

            <ServiceNameField isRequired />

            <Separator />

            <ServiceMonthlyCostField
                size={watch("size")}
                location={watch("location")}
                serviceName={os_name}
                reserve_public_ip={watch("reserve_public_ip")}
                failover_nodes={watch("failover_nodes")}
            />

            <BillingAccountField resourceType="managed_service" isRequired />

            <Separator />

            <CreateFormAction resourceType="managed_service" />
        </WHookForm>
    );
}

function ServiceMonthlyCostField({
    size,
    location,
    serviceName,
    reserve_public_ip,
    failover_nodes,
}: {
    size: SizeValue;
    location: string;
    serviceName: string;
    reserve_public_ip: boolean;
    failover_nodes: number;
}) {
    const priceList = useSuspenseQueryAtom(pricesAtom);
    const allImages = serviceImagesQuery.useSuspense();
    const imagePrices = allImages.find((i) => i.service_name === serviceName)!.prices;

    const price = getServiceCreatePrice(
        priceList,
        {
            ...sizeToVmPriceFields(size, true),
            location,
            price_multipliers: imagePrices,
            os_name: serviceName,
        },
        reserve_public_ip,
        failover_nodes,
    );

    return (
        <MonthlyCostField
            description={
                <>
                    The prices is approximate, actual cost of your resource will be calculated based on your actual
                    hourly usage.{" "}
                    <PricingModal defaultLocation={location}>
                        Please see how the cost of your resource is calculated.
                    </PricingModal>
                </>
            }
        >
            <MonthlyCostElement price={price} />
        </MonthlyCostField>
    );
}

function ServiceNameField(textFieldProps: WTextFieldProps) {
    const form = useFormContext<ServiceCreateInputs>();
    const { ref, props } = useAriaField("display_name", form, {
        required: textFieldProps.isRequired ? requiredMessage : false,
    });
    return (
        <WTextField {...props} label="Service name" {...textFieldProps}>
            <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Service" icon="jp-compute-cloud-icon">
            <p className="text-muted">
                Fully managed services - we handle the provisioning of underlying infrastructure and the management of
                the service.
            </p>
        </HeroBlock>
    );
}

/** Debugger to check if re-loading the form works (this is used to eg. retry creating a Service from the error notification) */
function ServiceCreateDebugger(props: ServiceCreateProps) {
    const currentInputsRef = useRef(props.inputs);
    const heldInputsRef = useRef(props.inputs);

    const [resetKey, setResetKey] = useState(0);
    return (
        <>
            <ServiceCreateForm
                key={resetKey}
                {...props}
                onChangeInputs={(newInputs) => {
                    currentInputsRef.current = newInputs;
                }}
                inputs={heldInputsRef.current}
            />
            <ContentPane className="HStack gap-1">
                <MaskIcon className="size-0.875rem i-lucide:bug color-muted" />
                <WButton
                    label="Reload form"
                    icon="i-lucide:refresh-ccw"
                    action={() => {
                        console.log("ServiceCreate: Reloading form");
                        heldInputsRef.current = currentInputsRef.current;
                        setResetKey((k) => k + 1);
                    }}
                />
                <WButton
                    label="Log inputs"
                    action={() => {
                        console.log(currentInputsRef.current);
                    }}
                />
            </ContentPane>
        </>
    );
}
