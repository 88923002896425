import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { SimpleMonthlyCostElement } from "../../components/forms/MonthlyCostField.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { sizeToVmPriceFields, type SizeValue } from "../compute/vmSizeSelectUtils.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getAppendedServiceFailoverPrice, type Price } from "../pricing/resourcePricing.ts";
import * as serviceImagesQuery from "./serviceImagesQuery.ts";

interface NodeWithPrice {
    node_count: number;
    price: Price;
    is_default?: boolean;
}

export interface FailoverNodeSelectCustom extends ConfiguredWSelectProps<NodeWithPrice, string> {
    size: SizeValue;
    location: string;
    serviceName: string;
}

export const DefaultNodeCount = 1;

function FailoverNodeIcon() {
    return <MaskIcon className="size-1.125rem jp-stack-icon text-primary" />;
}

function ShortInfo({ item: { price } }: { item: NodeWithPrice }) {
    return <SimpleMonthlyCostElement price={price} show_zero_as="NUMBER" />;
}

function getDefaultNode(items: readonly NodeWithPrice[]) {
    return items.find((s) => s.is_default) ?? items[0];
}

function getItemName(item: NodeWithPrice) {
    return item.node_count === 0 ? "No Fail-over" : `${item.node_count} node(s)`;
}

function FailoverNodeSelectCustom(props: FailoverNodeSelectCustom) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select failover node"
            dynamicDefault={getDefaultNode}
            getKey={(item) => String(item.node_count)}
            getTextValue={(item) => getItemName(item)}
            {...props}
        >
            {(item) => (
                <LeftRight icon={<FailoverNodeIcon />} title={getItemName(item)} right={<ShortInfo item={item} />} />
            )}
        </WSelect>
    );
}

export type FailoverNodeSelectLoaderProps = Omit<FailoverNodeSelectCustom, "items">;

function FailoverNodeSelectLoader(props: FailoverNodeSelectLoaderProps) {
    const priceList = useSuspenseQueryAtom(pricesAtom);

    const allImages = serviceImagesQuery.useSuspense();
    const imagePrices = allImages.find((i) => i.service_name === props.serviceName)!.prices;

    const items: NodeWithPrice[] = [];
    for (let i = 0; i <= 1; i++) {
        const price = getAppendedServiceFailoverPrice(
            priceList,
            {
                ...sizeToVmPriceFields(props.size, true),
                location: props.location,
                price_multipliers: imagePrices,
                os_name: props.serviceName,
            },
            false,
            i,
        );
        items.push({
            node_count: i,
            price,
            is_default: i === DefaultNodeCount,
        });
    }

    const propsWithItems: FailoverNodeSelectCustom = {
        ...props,
        items,
    };
    return <FailoverNodeSelectCustom {...propsWithItems} />;
}

export function FailoverNodeSelect(props: FailoverNodeSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <FailoverNodeSelectCustom {...props} {...renderProps} />}>
            <FailoverNodeSelectLoader {...props} />
        </WSelectWrapper>
    );
}
